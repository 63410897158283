<template>
    <div class="page">
        <tableview ref="tbview" v-model="searchFormData" :url="API.Market.Energy.Business.list" :outBt="0">
            <!--搜索自定义内容-->
            <template #search>
                <el-input v-model="searchFormData.names" size="small" clearable placeholder="按名称查询" />
                <el-input v-model="searchFormData.phone" size="small" clearable placeholder="按手机号查询" />
            </template>
            <template #bar>
                <el-button @click="addOne" size="small" icon="el-icon-plus" plain>新建商家</el-button> 
            </template>
            <!--表格内容-->
            <template #column>
                <el-table-column prop="id" label="商家ID"></el-table-column>
                <el-table-column prop="names" label="商家名称"></el-table-column>
                <el-table-column prop="phone" label="商家电话"></el-table-column>
                <el-table-column prop="runtime" label="营业时间"></el-table-column>
                <el-table-column prop="address" label="商家地址"></el-table-column>
                <el-table-column prop="utime" label="最后编辑时间"></el-table-column>
                <el-table-column prop="utime" label="操作">
                    <template slot-scope="{row}">
                        <el-button size="small" plain @click="edit(row)">编辑</el-button>
                        <el-button size="small" plain @click="del(row)">删除</el-button>
                    </template>
                </el-table-column>
            </template>
        </tableview>

        <!-- 编辑 -->
        <el-dialog title="新增/编辑" :visible.sync="editDialogShow" :close-on-click-modal="false" :close-on-press-escape="false" width="400px">
            <el-form :model="editDialogFormData" :rules="rules" ref="editForm" label-width="120px" class="form">
                <el-form-item label="商家名称" prop="names">
                    <el-input v-model="editDialogFormData.names" size="mini"></el-input>
                </el-form-item>
                <el-form-item label="商家电话" prop="phone">
                    <el-input v-model="editDialogFormData.phone" size="mini"></el-input>
                </el-form-item>
                <el-form-item label="营业时间" prop="runtime">
                    <el-input v-model="editDialogFormData.runtime" size="mini"></el-input>
                </el-form-item>
                <el-form-item label="商家地址" prop="address">
                    <el-input v-model="editDialogFormData.address" size="mini"></el-input>
                    <el-button type="text" circle icon="el-icon-location-outline" @click="openMap">选择</el-button>
                    <span>{{ editDialogFormData.address_str }}</span>
                </el-form-item>
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button type="primary" size="small" plain icon="el-icon-check" @click="saveEdit()">保 存</el-button>
                <el-button size="small" plain icon="el-icon-close" @click="editDialogShow = false">取 消</el-button>
            </div>
        </el-dialog>
        <div v-if="showMap">
			<BMap ref="bmap" @mapChooseComplete="handleMapComplete" @mapClose="showMap = false"></BMap>
		</div>    
    </div>
</template>

<script setup>
import Vue, { ref } from 'vue'
import API from '@/service/api.js'
import { MessageBox, Notification , Message} from 'element-ui'
import tableview from '@/components/tableview'
import BMap from '@/components/b-map'
import {bd2gd,gd2bd} from '../../../utils/util'

const searchFormData = ref({});
const editForm = ref(null);
const rules = ref({
	names: [{
		required: true,
		message: '请输入商家名称',
		trigger: 'change'
	}],
	phone: [{
		required: true,
		message: '请输入商家电话',
		trigger: 'change'
	}],
	address: [{
		required: true,
		message: '请输入商家地址',
		trigger: 'change'
	}],
	runtime: [{
		required: true,
		message: '请输入运营时间',
		trigger: 'change'
	}]
});
const editDialogShow = ref(false);
const editDialogFormData = ref({});
const showMap = ref(false);
// 重置form
function resetFormData() {
	editDialogFormData.value = {}
	if (editForm.value) {
		editForm.value.resetFields()
	}
}
// 新增
function addOne() {
	resetFormData()
	editDialogShow.value = true
}
// 编辑
async function edit(row) {
	resetFormData();
	editDialogFormData.value = Object.assign({}, row);
	delete editDialogFormData.value._XID;
	editDialogShow.value = true
}
const tbview = ref(null);
// 删除
function del(row) {
    if(row.use > 0){
        Notification({title: '不能删除',message: '已经有绑定了',type: 'warning'})
        return;
    }
	MessageBox.confirm('确定要删除该记录吗', '提示', {
		cancelButtonClass: 'btn-custom-cancel',
		confirmButtonText: '确定',
		cancelButtonText: '取消',
		type: 'warning'
	}).then(async () => {
		const params = {
			id: row.id
		}
		await API.Market.Energy.Business.del(params)
		Notification({title: '成功',message: '删除成功',type: 'success'})
		tbview.value.getlist();
	})
}
// 编辑保存
function saveEdit() {
	editForm.value.validate(async valid => {
		if (valid) {
			let params = Object.assign({}, editDialogFormData.value);
			if (editDialogFormData.value.id > 0) {
				await API.Market.Energy.Business.edit(params);
			} else {
				await API.Market.Energy.Business.add(params);
			}
			Notification({title: '成功',message: '操作成功',type: 'success'});
			editDialogShow.value = false;
			tbview.value.getlist();
		}
	})
}
// 打开地图
const bmap = ref(null);
function openMap(){
    showMap.value = true;
    setTimeout(()=>{
        if(editDialogFormData.value.longitude && editDialogFormData.value.latitude){
            const point = gd2bd(editDialogFormData.value.longitude, editDialogFormData.value.latitude);
            point.lng = Number(point.lng.toFixed(6));
            point.lat = Number(point.lat.toFixed(6));
            bmap.value.getClickInfo({point});
        }
    }, 500);
}
// 地图选择回调
function handleMapComplete(address) {
    showMap.value = false
    editDialogFormData.value.address_str = address.province + address.city + address.district;
    const point = bd2gd(address.lng, address.lat);
    editDialogFormData.value.longitude = point.lng.toFixed(6);
    editDialogFormData.value.latitude = point.lat.toFixed(6);
}
</script>

