<template>
    <div class="page">
        <Tables ref="xTable" :tableData="List" :totalPage="totalPage" :isLoading="isLoading" :searchFormData="searchFormData" :showRefresh="true" :showSearch="true" :showAdd="$hasAccess('energy.shop/add')" addName="新增" @getList="getList" @clearSearch="clearSearch" @addItemInTable="addOne">
            <!--搜索自定义内容-->
            <el-form-item slot="search-item">
                <el-input v-model="searchFormData.names" size="small" clearable placeholder="按名称查询">
                </el-input>
            </el-form-item>
            <el-form-item slot="search-item">
                <el-select v-model="searchFormData.get_type" placeholder="请选择商品类型" clearable size="small">
                    <el-option v-for="(v,k) in get_types" :label="v" :value="parseInt(k)" :key="k"></el-option>
                </el-select>
            </el-form-item>            
            <el-form-item slot="search-item">
                <el-select v-model="searchFormData.city" placeholder="请选择归属城市" clearable size="small">
                    <el-option label="全部" value="全部"></el-option>
                    <el-option v-for="(item,k) in $userArea" :key="k" :label="item" :value="item"></el-option>
                </el-select>
            </el-form-item>
            <el-form-item slot="search-item">
                <el-select v-model="searchFormData.platform" placeholder="请选择生效平台" clearable size="small">
                    <el-option v-for="(item,k) in $platform" :key="k" :label="item" :value="parseInt(k)"></el-option>
                </el-select>
            </el-form-item>
            <el-form-item slot="search-item">
                <el-select v-model="searchFormData.status" placeholder="请选择上架状态" clearable size="small">
                    <el-option label="上架" :value="1"></el-option>
                    <el-option label="下架" :value="0"></el-option>
                </el-select>
            </el-form-item>
            <!--表格内容-->
            <vxe-table-column slot="table-item" field="names" title="商品名称" />
            <vxe-table-column slot="table-item" field="get_type_text" title="类型" />
            <vxe-table-column slot="table-item" field="energy" title="需付能量值" />
            <vxe-table-column slot="table-item" field="city" title="归属城市" />
            <vxe-table-column slot="table-item" field="platform" title="生效平台">
                <template v-slot="{row}">
                    {{ $platform[row.platform] }}
                </template>
            </vxe-table-column>
            <vxe-table-column slot="table-item" field="btime" title="有效时间" width="200">
                <template v-slot="{row}">
                    {{ row.btime + " ~ " + row.etime }}
                </template>
            </vxe-table-column>
            <vxe-table-column slot="table-item" field="status_text" title="上架状态" />
            <vxe-table-column slot="table-item" field="sortval" title="排序" />
            <vxe-table-column slot="table-item" field="utime" title="编辑时间" />
            <vxe-table-column slot="table-item" title="操作" width="300">
                <template v-slot="{ row }">
                    <el-button size="small" plain @click="edit(row)">编辑</el-button>
                    <!--<el-button size="small" plain @click="del(row)">删除</el-button>-->
                </template>
            </vxe-table-column>
        </Tables>

        <!-- 编辑 -->
        <el-dialog title="新增/编辑" :visible.sync="editDialogShow" :close-on-click-modal="false" :close-on-press-escape="false" width="900px">
            <el-form :model="editDialogFormData" :rules="rules" ref="editForm" label-width="120px" class="form">
                <el-form-item label="商品名称" prop="names">
                    <el-input v-model="editDialogFormData.names" size="mini"></el-input>
                </el-form-item>
                <el-form-item label="所需能量值" prop="energy">
                    <el-input-number v-model="editDialogFormData.energy" controls-position="right" size="mini" :step="100" :min="1" :max="999999"></el-input-number>
                </el-form-item>
                <el-form-item label="原价能量值" prop="origin_energy">
                    <el-input-number v-model="editDialogFormData.origin_energy" controls-position="right" size="mini" :step="100" :min="0" :max="999999"></el-input-number>
                </el-form-item>
                <el-form-item label="商品标签" prop="tag">
                    <el-select size="mini" v-model="editDialogFormData.tag">
                        <el-option v-for="item in tags" :label="item.v" :value="item.k" :key="item.k"></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="归属城市" prop="city">
					<el-select v-model="editDialogFormData.city" placeholder="请选择" style="width:100%">
						<el-option label="全部" value="全部"></el-option>
						<el-option v-for="(item,k) in $userArea" :key="k" :label="item" :value="item"></el-option>
					</el-select>
				</el-form-item>
                <el-form-item label="生效平台" prop="platform">
					<el-select v-model="editDialogFormData.platform" placeholder="请选择" style="width:100%">
						<el-option v-for="(item,k) in $platform" :key="k" :label="item" :value="parseInt(k)"></el-option>
					</el-select>
				</el-form-item>
                <el-form-item label="所属商家" prop="business_id" v-if="editDialogFormData.get_type == 3">
					<el-select v-model="editDialogFormData.business_id" placeholder="请选择" style="width:100%" @change="changeBusiness">
						<el-option v-for="(item,k) in businessList" :key="k" :label="item.names" :value="item.id"></el-option>
					</el-select>
				</el-form-item>
                <el-form-item label="商家地址" v-if="editDialogFormData.get_type == 3 && editDialogFormData.business_id>0">
					<span>{{ editDialogFormData.business_address }}</span>
				</el-form-item>
                <el-form-item label="商品类型" prop="get_type">
                    <el-select size="mini" v-model="editDialogFormData.get_type">
                        <el-option v-for="(v,k) in get_types" :label="v" :value="parseInt(k)" :key="k"></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item v-if="editDialogFormData.get_type == 1" label="商品内容" prop="get_ids">
                    <el-button @click="addGetIds">增加优惠券</el-button><br />
                    <div v-for="(it,idx) in editDialogFormData.get_ids" :key="idx">
                        <el-select size="mini" v-model="editDialogFormData.get_ids[idx][0]">
                            <el-option v-for="(item) in couponList" :label="item.coupon_name" :value="item.coupon_id" :key="item.coupon_id"></el-option>
                        </el-select>
                        <el-input-number v-model="editDialogFormData.get_ids[idx][1]" size="mini" controls-position="right" :min="1" :max="100"></el-input-number>
                        <el-button size="mini"  type="danger" icon="el-icon-delete" @click="delGetIds(idx)"></el-button><br/>
                    </div>
                </el-form-item>
                <el-form-item v-else label="核销后赠券" prop="get_ids">
                    <el-button @click="addGetIds">增加优惠券</el-button><a style="color:#aaa">(未添加则不赠送优惠券)</a><br />
                    <div v-for="(it,idx) in editDialogFormData.get_ids" :key="idx">
                        <el-select size="mini" v-model="editDialogFormData.get_ids[idx][0]">
                            <el-option v-for="(item) in couponList" :label="item.coupon_name" :value="item.coupon_id" :key="item.coupon_id"></el-option>
                        </el-select>
                        <el-input-number v-model="editDialogFormData.get_ids[idx][1]" size="mini" controls-position="right" :min="1" :max="100"></el-input-number>
                        <el-button size="mini"  type="danger" icon="el-icon-delete" @click="delGetIds(idx)"></el-button><br/>
                    </div>
                </el-form-item>
                <el-form-item label="封面图" prop="img_face">
                    <upload style="display: flex;margin: 0 10px;" type="image" :imgWidth="80" :imgHeight="80" :fileList="editDialogFormData.img_face_file" @changeFile="faceFile($event)"></upload>
                </el-form-item>
                <el-form-item label="详情图" prop="img_detail">
                    <upload style="display: flex;margin: 0 10px;" type="image" :imgWidth="80" :imgHeight="80" :fileList="editDialogFormData.img_detail_file" @changeFile="detailFile($event)"></upload>
                </el-form-item>
                <el-form-item label="商品描述" prop="description">
                    <!--<el-input v-model="editDialogFormData.description" size="mini" type="textarea"></el-input>-->
                    <quill-editor v-model="editDialogFormData.description"></quill-editor>
                </el-form-item>
                <el-form-item label="排序值(小排前)" prop="sortval">
                    <el-input v-model="editDialogFormData.sortval" size="mini"></el-input>
                </el-form-item>
                <el-form-item label="开始时间" prop="btime">
					<el-date-picker class="from-item-width" unlink-panels v-model="editDialogFormData.btime"
						type="date" value-format="yyyy-MM-dd" placeholder="开启时间"></el-date-picker>
				</el-form-item>
				<el-form-item label="结束时间" prop="etime">
					<el-date-picker class="from-item-width" unlink-panels v-model="editDialogFormData.etime"
						type="date" value-format="yyyy-MM-dd" placeholder="结束时间"></el-date-picker>
				</el-form-item>
				<el-form-item label="上架状态" prop="status">
					<el-switch v-model="editDialogFormData.status" :active-value="1" :inactive-value="0" />
				</el-form-item>
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button type="primary" size="small" plain icon="el-icon-check" @click="saveEdit()">保 存
                </el-button>
                <el-button size="small" plain icon="el-icon-close" @click="closeDialog">取 消</el-button>
            </div>
        </el-dialog>        
    </div>
</template>

<script setup>
import { ref, onMounted } from 'vue'
import Vue from 'vue'
import store from '@/store'
import API from '@/service/api.js'
import { MessageBox, Notification , Message} from 'element-ui'
import Tables from '@/components/tables'
import upload from '@/components/upload'
import { get_types, tags, status_vals } from './const.js';
import { quillEditor } from 'vue-quill-editor'
import "quill/dist/quill.core.css"; // import styles
import "quill/dist/quill.snow.css"; // for snow theme
import "quill/dist/quill.bubble.css"; // for bubble theme

const isLoading = ref(false);
const totalPage = ref(0);
const searchFormData = ref({});
const List = ref([]);
const couponList = ref([]);
const businessList = ref([]); //商家列表
const editForm = ref(null);
const rules = ref({
	names: [{
		required: true,
		message: '请输入商品名称',
		trigger: 'change'
	}],
	energy: [{
        type: 'number',
        min:1,
		required: true,
		message: '请输入所需能量值',
		trigger: 'change'
	}],
	img_face: [{
        type: 'integer',
        min:1,
		required: true,
		message: '请添加封面图'
	}],
	img_detail: [{
        type: 'integer',
        min:1,
		required: true,
		message: '请添加详情图'
	}],
	description: [{
		required: true,
		message: '请输入商品描述',
		trigger: 'change'
	}],
	sortval: [{
		required: true,
		message: '请输入排序值',
		trigger: 'change'
	}],
	btime: [{
		required: true,
		message: '请输入开始时间',
		trigger: 'change'
	}],
	etime: [{
		required: true,
		message: '请输入结束时间',
		trigger: 'change'
	}],
    get_ids: [{
		required: true,
		message: '请添加优惠券',
        validator: (rule, value, callback)=>{
            if(editDialogFormData.value.get_type == 1 && editDialogFormData.value.get_ids.length < 1) return callback(new Error('请添加优惠券'));
            callback();
        }
	}],
    business_id: [{
		required: true,
		message: '请选择商家',
		trigger: 'change',
        validator: (rule, value, callback)=>{
            if(editDialogFormData.value.get_type == 3 && !(editDialogFormData.value.business_id > 0)) return callback(new Error('请选择商家'));
            callback();
        }
	}],
});
const editDialogShow = ref(false);
const editDialogFormData = ref({get_type:1,get_ids:[]});
const xTable = ref(null);

onMounted(()=>{
    //获取优惠券列表
    API.Market.Coupon.minlist({}).then((res)=>{
        for (let d of res) {
            if (d.max_money != null) {
                d.coupon_name = d.coupon_name + '(' + d.max_money + '元)';
            }
        }
        couponList.value = res;				
    });
    //获取商家列表
    API.Market.Energy.Business.list({size:1000}).then((res)=>{
        businessList.value = res.data;				
    });
});
// 表格列表
async function getList(currentPage, pageSize) {
	let params = {
		token: store.state.user.token,
		page: currentPage,
		size: pageSize
	}
	params = Object.assign(params, searchFormData.value);
	isLoading.value = true
	const res = await API.Market.Energy.Shop.list(params);
    for(let d of res.data){
        d.get_type_text = get_types[d.get_type];
        d.status_text = status_vals[d.status];
        d.get_ids = JSON.parse(d.get_ids||'[]');
        if(d.img_face_url){
            d.img_face_file = [{attach_id:d.img_face,url:d.img_face_url}];
        }
        if(d.img_detail_url){
            d.img_detail_file = [{attach_id:d.img_detail,url:d.img_detail_url}];
        }
    }
	List.value = res.data;
	totalPage.value = res.total;
	isLoading.value = false;
    
    setTimeout(()=>{
		let htmlnode = document.getElementsByClassName("vxe-table--body-wrapper");
		for(let nd of htmlnode){
			nd.style.height = 'auto';
		}
		htmlnode = document.getElementsByTagName("table");
		for(let nd of htmlnode){
			nd.style.width = '100%';
		}
	}, 200);
}
// 重置搜索
function clearSearch() {
	searchFormData.value = {}
}
// 重置form
function resetFormData() {
	editDialogFormData.value = { get_type: 1, get_ids:[], sortval:0, tag:0, city:"全部", platform:0, img_face:0, img_detail:0}
	if (editForm.value) {
		editForm.value.resetFields()
	}
}
// 新增
function addOne() {
	resetFormData()
	editDialogShow.value = true
}
// 编辑
async function edit(row) {
	resetFormData();
	editDialogFormData.value = Object.assign({}, row);
	delete editDialogFormData.value._XID;
    if(!editDialogFormData.value.get_ids) editDialogFormData.value.get_ids = [];
    if(editDialogFormData.value.business_id > 0) changeBusiness(editDialogFormData.value.business_id);
	editDialogShow.value = true
}
// 删除
function del(row) {
	MessageBox.confirm('确定要删除该记录吗', '提示', {
		cancelButtonClass: 'btn-custom-cancel',
		confirmButtonText: '确定',
		cancelButtonText: '取消',
		type: 'warning'
	}).then(async () => {
		const params = {
			token: store.state.user.token,
			id: row.id
		}
		await API.Market.Energy.Shop.del(params)
		Notification({title: '成功',message: '删除成功',type: 'success'})
		xTable.value.refreshTable();
	})
}
// 关闭弹窗
function closeDialog() {
	editDialogShow.value = false;
}
// 编辑保存
function saveEdit() {
    console.log(editDialogFormData.value);
	editForm.value.validate(async valid => {
		if (valid) {
            editDialogFormData.value.description = editDialogFormData.value.description.replace(/\<img src/g, "<img style='max-width:100%' src");
			let params = Object.assign({ token: store.state.user.token }, editDialogFormData.value);
            params.get_ids = JSON.stringify(params.get_ids);
            //console.log(editDialogFormData.value.description);
			if (editDialogFormData.value.id > 0) {
				await API.Market.Energy.Shop.edit(params);
			} else {
				await API.Market.Energy.Shop.add(params);
			}
			Notification({title: '成功',message: '操作成功',type: 'success'});
			closeDialog();
			xTable.value.refreshTable();
		}
	})
}
function addGetIds(){
    editDialogFormData.value.get_ids.push([0, 1]);
}
function delGetIds(idx){
    editDialogFormData.value.get_ids.splice(idx, 1);
}
function faceFile(fileInfo) { //上传的文件
    if(fileInfo.length > 0){
        editDialogFormData.value.img_face_file = fileInfo;
        editDialogFormData.value.img_face = fileInfo[0].attach_id|0;
        editDialogFormData.value.img_face_url = fileInfo[0].url;
    }else{
        editDialogFormData.value.img_face_file = [];
        editDialogFormData.value.img_face = 0;
        editDialogFormData.value.img_face_url = "";
    }
}
function detailFile(fileInfo) { //上传的文件
    if(fileInfo.length > 0){
        editDialogFormData.value.img_detail = fileInfo[0].attach_id|0;
        editDialogFormData.value.img_detail_file = fileInfo;
        editDialogFormData.value.img_detail_url = fileInfo[0].url;
    }else{
        editDialogFormData.value.img_detail = 0;
        editDialogFormData.value.img_detail_file = [];
        editDialogFormData.value.img_detail_url = "";
    }
}
function changeBusiness(e){ //修改商家
    for(let b of businessList.value){
        if(b.id == e){
            editDialogFormData.value.business_address = b.address;
            break;
        }
    }
}
</script>

