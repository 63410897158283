<template>
    <div class="page">
        <Tables ref="xTable" :tableData="List" :totalPage="totalPage" :isLoading="isLoading" :searchFormData="searchFormData" :showRefresh="true" :showSearch="true" :showAdd="$hasAccess('energy.shop/add')" addName="新增" @getList="getList" @clearSearch="clearSearch" @addItemInTable="addOne">
            <!--搜索自定义内容-->
            <el-form-item slot="search-item">
                <el-input v-model="searchFormData.names" size="small" clearable placeholder="按名称查询">
                </el-input>
            </el-form-item>
            <!--表格内容-->
            <vxe-table-column slot="table-item" field="names" title="名称" />
            <vxe-table-column slot="table-item" field="deduction_money" title="抵扣门槛">
                <template v-slot="{row}">
                    普通用户:{{ row.deduction_money }}元<br/>
                    VIP:{{ row.deduction_vip_money }}元
                </template>
            </vxe-table-column>
            <vxe-table-column slot="table-item" field="energy" title="最高抵扣">
                <template v-slot="{row}">
                    普通用户:{{ row.deduction_max }}元<br/>
                    VIP:{{ row.deduction_vip_max }}元
                </template>
            </vxe-table-column>
            <vxe-table-column slot="table-item" field="btime" title="有效时间" width="200">
                <template v-slot="{row}">
                    {{ row.btime + " ~ " + row.etime }}
                </template>
            </vxe-table-column>
            <vxe-table-column slot="table-item" field="status_text" title="状态" />
            <vxe-table-column slot="table-item" field="utime" title="更新时间" />
            <vxe-table-column slot="table-item" title="操作" width="300">
                <template v-slot="{ row }">
                    <el-button size="small" plain @click="edit(row)">编辑</el-button>
                    <el-button size="small" plain @click="del(row)">删除</el-button>
                </template>
            </vxe-table-column>
        </Tables>

        <!-- 编辑 -->
        <el-dialog title="新增/编辑" :visible.sync="editDialogShow" :close-on-click-modal="false" :close-on-press-escape="false" width="700px">
            <el-form :model="editDialogFormData" :rules="rules" ref="editForm" label-width="140px" class="form">
                <el-form-item label="名称" prop="names">
                    <el-input v-model="editDialogFormData.names" size="mini"></el-input>
                </el-form-item>
                <el-form-item label="普通用户抵扣门槛" prop="deduction_money">
                    <el-input v-model="editDialogFormData.deduction_money" size="mini"></el-input>
                </el-form-item>
                <el-form-item label="普通用户最高抵" prop="deduction_max">
                    <el-input v-model="editDialogFormData.deduction_max" size="mini"></el-input>
                </el-form-item>
                <el-form-item label="VIP用户抵扣门槛" prop="deduction_vip_money">
                    <el-input v-model="editDialogFormData.deduction_vip_money" size="mini"></el-input>
                </el-form-item>
                <el-form-item label="VIP用户最高抵" prop="deduction_vip_max">
                    <el-input v-model="editDialogFormData.deduction_vip_max" size="mini"></el-input>
                </el-form-item>
                <el-form-item label="开始时间" prop="btime">
					<el-date-picker class="from-item-width" unlink-panels v-model="editDialogFormData.btime"
						type="date" value-format="yyyy-MM-dd" placeholder="开启时间"></el-date-picker>
				</el-form-item>
				<el-form-item label="结束时间" prop="etime">
					<el-date-picker class="from-item-width" unlink-panels v-model="editDialogFormData.etime"
						type="date" value-format="yyyy-MM-dd" placeholder="结束时间"></el-date-picker>
				</el-form-item>
                <el-form-item label="参与电站" prop="group_ids">
                    <el-select v-model="editDialogFormData.group_ids" multiple>
                        <el-option v-for="(item,key) in groupList" :label="item.group_name" :value="item.group_id"></el-option>
                    </el-select>                    
                </el-form-item>
				<el-form-item label="状态" prop="status">
					<el-switch v-model="editDialogFormData.status" :active-value="1" :inactive-value="0" />
				</el-form-item>
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button type="primary" size="small" plain icon="el-icon-check" @click="saveEdit()">保 存
                </el-button>
                <el-button size="small" plain icon="el-icon-close" @click="closeDialog">取 消</el-button>
            </div>
        </el-dialog>        
    </div>
</template>

<script setup>
import { ref } from 'vue'
import Vue from 'vue'
import store from '@/store'
import API from '@/service/api.js'
import { MessageBox, Notification , Message} from 'element-ui'
import Tables from '@/components/tables'
import upload from '@/components/upload'

const isLoading = ref(false);
const totalPage = ref(0);
const searchFormData = ref({});
const List = ref([]);
const status_vals = {"0":"停用", "1":"启用"};
const groupList = ref([]);
const editForm = ref(null);
const rules = ref({
	names: [{
		required: true,
		message: '请输入名称',
		trigger: 'change'
	}],
	deduction_money: [{
		required: true,
		message: '请输入普通用户抵扣门槛',
		trigger: 'change'
	}],
	deduction_max: [{
		required: true,
		message: '请输入普通用户最高抵',
		trigger: 'change'
	}],
	deduction_vip_money: [{
		required: true,
		message: '请输入VIP用户抵扣门槛',
		trigger: 'change'
	}],
	deduction_vip_max: [{
		required: true,
		message: '请输入VIP用户最高抵',
		trigger: 'change'
	}],
	btime: [{
		required: true,
		message: '请输入开始时间',
		trigger: 'change'
	}],
	etime: [{
		required: true,
		message: '请输入结束时间',
		trigger: 'change'
	}],
	group_ids: [{
		required: true,
		message: '请选择站点',
		trigger: 'change'
	}]
});
const editDialogShow = ref(false);
const editDialogFormData = ref({});
const xTable = ref(null);
//获取电站列表
function getGroup(){
    API.Device.GetGroupMinList({token: store.state.user.token}).then((res)=>{
        groupList.value = res;				
    });
}
getGroup();
// 表格列表
async function getList(currentPage, pageSize) {
	let params = {
		token: store.state.user.token,
		page: currentPage,
		size: pageSize
	}
	params = Object.assign(params, searchFormData.value);
	isLoading.value = true
	const res = await API.Market.Energy.Group.list(params);
    for(let d of res.data){
        d.status_text = status_vals[d.status];
        d.group_ids = JSON.parse(d.group_ids||'[]');
    }
	List.value = res.data;
	totalPage.value = res.total;
	isLoading.value = false;

    setTimeout(()=>{
		let htmlnode = document.getElementsByClassName("vxe-table--body-wrapper");
		for(let nd of htmlnode){
			nd.style.height = 'auto';
		}
		htmlnode = document.getElementsByTagName("table");
		for(let nd of htmlnode){
			nd.style.width = '100%';
		}
	}, 200);
}
// 重置搜索
function clearSearch() {
	searchFormData.value = {}
}
// 重置form
function resetFormData() {
	editDialogFormData.value = {}
	if (editForm.value) {
		editForm.value.resetFields()
	}
}
// 新增
function addOne() {
	resetFormData()
	editDialogShow.value = true
}
// 编辑
async function edit(row) {
	resetFormData();
	editDialogFormData.value = Object.assign({}, row);
	delete editDialogFormData.value._XID;
	editDialogShow.value = true
}
// 删除
function del(row) {
	MessageBox.confirm('确定要删除该记录吗', '提示', {type: 'warning'}).then(async () => {
		const params = {
			token: store.state.user.token,
			id: row.id
		}
		await API.Market.Energy.Group.del(params)
		Notification({title: '成功',message: '删除成功',type: 'success'})
		xTable.value.refreshTable();
	})
}
// 关闭弹窗
function closeDialog() {
	editDialogShow.value = false;
}
// 编辑保存
function saveEdit() {
	editForm.value.validate(async valid => {
		if (valid) {
			let params = Object.assign({ token: store.state.user.token }, editDialogFormData.value);
            params.group_ids = JSON.stringify(params.group_ids);
			if (editDialogFormData.value.id > 0) {
				await API.Market.Energy.Group.edit(params);
			} else {
				await API.Market.Energy.Group.add(params);
			}
			Notification({title: '成功',message: '操作成功',type: 'success'});
			closeDialog();
			xTable.value.refreshTable();
		}
	})
}
</script>

