<template>
	<div class="page">
		<el-tabs v-model="activeName">
			<el-tab-pane label="能量值设置" name="first" class="box">
				<div class="mintitle">能量值获得功能</div>
				<el-switch active-text="开启" inactive-text="关闭" v-model="config.getStatus"></el-switch><br/>
				<template v-for="(item, key) in $userArea">
					{{ item }} 每 1 度电可获得 <el-input-number v-model="config.energy[item]" size="mini" controls-position="right" :step="0.1" :min="0" :max="10" :precision="1"></el-input-number> 能量值<br/>
				</template>
				<div class="mintitle">能量值抵扣功能</div>
				<el-switch active-text="开启" inactive-text="关闭" v-model="config.useStatus"></el-switch><br/>
				1元= <el-input size="mini" style="width: 100px;" v-model="config.userMoney"></el-input> 能量值(普通用户)<br/>
				1元= <el-input size="mini" style="width: 100px;" v-model="config.userVipMoney"></el-input> 能量值(VIP用户)<br/><br/>
				<el-button size="small" @click="configEdit">保存</el-button>
			</el-tab-pane>
			<el-tab-pane label="商品设置" name="second">
				<shop></shop>
			</el-tab-pane>
			<el-tab-pane label="能量值站点" name="third">
				<group></group>
			</el-tab-pane>
			<el-tab-pane label="服务商家" name="fourth">
				<business></business>
			</el-tab-pane>
		</el-tabs>
	</div>
</template>

<script setup>
import { ref } from 'vue'
import Vue from 'vue'
import store from '@/store'
import API from '@/service/api.js'
import { MessageBox, Notification , Message} from 'element-ui'
import Tables from '@/components/tables'
import shop from './shop.vue'
import group from './group.vue'
import business from './business.vue'

const activeName = ref("first");
/////////////////////////////////////配置/////////////////////////////////////
const config = ref({energy:{}});
const $userArea = ref(Vue.prototype.$userArea);
function configGet(){
	API.System.Config.detail({token: store.state.user.token, types:"energy"}).then((res)=>{
		config.value = res || {energy:{}};
	});
}
configGet();
function configEdit(){
	API.System.Config.edit({token: store.state.user.token, types:"energy", cfg:JSON.stringify(config.value)}).then((res)=>{
		Notification({title: '成功',message: '操作成功',type: 'success'})
	});
}
</script>

<style lang="scss" scoped>
.el-tab-pane {
	min-height: 300px;
}
.box{
	padding: 30px;
	line-height: 30px;
	.mintitle {
		font-weight: bold;
		padding: 20px 0;
	}
}
.countBox{
	background-color: #fff;
	margin-top: 8px;
	display: flex;
	padding: 20px 80px;
	div{
		margin-right: 160px;
	}
	a{
		font-size: 24px;
		font-weight: bold;
	}
}
</style>
